// import project1 from "./../img/projects/01.jpg";
// import project1Big from "./../img/projects/01-big.jpg";

// import project2 from "./../img/projects/02.jpg";
// import project2Big from "./../img/projects/02-big.jpg";

// import project3 from "./../img/projects/03.jpg";
// import project3Big from "./../img/projects/03-big.jpg";

// import project4 from "./../img/projects/04.jpg";
// import project4Big from "./../img/projects/04-big.jpg";

// import project5 from "./../img/projects/05.jpg";
// import project5Big from "./../img/projects/05-big.jpg";

// import project6 from "./../img/projects/06.jpg";
// import project6Big from "./../img/projects/06-big.jpg";

const projects = [
    // {
    //     title: "Book Finder",
    //     img: project1,
    //     imgBig: project1Big,
    //     gitHubLink: "https://github.com"
    // },
    // {
    //     title: "Video service",
    //     img: project2,
    //     imgBig: project2Big,
    //     gitHubLink: "https://github.com"
    // },
    // {
    //     title: "Video portal",
    //     img: project3,
    //     imgBig: project3Big,
    //     gitHubLink: "https://github.com"
    // },
    // {
    //     title: "Dating app",
    //     img: project4,
    //     imgBig: project4Big,
    //     gitHubLink: ""
    // },
    // {
    //     title: "Landing",
    //     img: project5,
    //     imgBig: project5Big,
    //     gitHubLink: ""
    // },
    // {
    //     title: "Gaming community",
    //     img: project6,
    //     imgBig: project6Big,
    //     gitHubLink: ""
    // }
]

export {projects}